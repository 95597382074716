@import url('https://fonts.cdnfonts.com/css/tt-commons');
@import url('https://fonts.cdnfonts.com/css/poppins');
@tailwind base;
@tailwind components;
@tailwind utilities;

body ,html {
  margin: 0;
  font-family: 'TT Commons', sans-serif;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
  position: relative;
  min-height: 100vh;
  color: #fff;
  background-color: black;
  scroll-behavior: smooth ;
}

@media only screen and (max-width: 1200px) {
  html {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1024px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (max-width: 720px) {
  html {
    font-size: 12px;
  }
}

@media only screen and (max-width: 640px) {
  html {
    font-size: 10px;
  }
}

@media only screen and (max-width: 425px) {
  html {
    font-size: 8px;
  }
}